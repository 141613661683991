// DoctorScheduleList.jsx

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import AdminPanel from './AdminPanel';

const DoctorScheduleList = () => {
    const [schedules, setSchedules] = useState([]);

    useEffect(() => {
        // Memuat daftar jadwal dokter dari backend saat komponen dimuat
        fetchDoctorSchedules();
    }, []);

    const fetchDoctorSchedules = async () => {
        try {
            const response = await axios.get('http://localhost:4000/jadwal-dokter');
            setSchedules(response.data);
        } catch (error) {
            console.error('Error fetching doctor schedules:', error);
        }
    };

    const handleDeleteSchedule = async (id) => {
        try {
            const response = await axios.delete(`http://localhost:4000/jadwal-dokter/${id}`);
            console.log('Schedule deleted:', response.data);
            fetchDoctorSchedules(); // Memuat kembali daftar jadwal setelah penghapusan
        } catch (error) {
            console.error('Error deleting schedule:', error);
        }
    };

    return (
        <div className="container mx-auto">
            <h1 className="text-3xl font-bold mb-4 text-center">Doctor Schedules</h1>
            {/* Tambahkan navigasi atau tombol untuk menuju halaman admin */}
            <div className="mb-4 text-center">
                <a href="/admin" className="bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded">Admin Panel</a>
            </div>
            
            {/* Daftar jadwal dokter */}
            <ul>
                {schedules.map(schedule => (
                    <li key={schedule.id} className="flex gap-4 my-2 p-2 border rounded-2xl mx-auto w-96 text-center bg-blue-200 justify-between items-center">
                        <div className='text-center'>{schedule.dokter}</div>
                        <div className='hidden'>{schedule.hari}</div>
                        <div className='bg-red-400'>{schedule.jam_mulai} - {schedule.jam_selesai}</div>
                        {/* Tombol hapus untuk setiap jadwal dokter */}
                        <button onClick={() => handleDeleteSchedule(schedule.id)} className="bg-red-500 hover:bg-red-700 text-white py-1 px-2 mt-2 rounded">Hapus</button>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default DoctorScheduleList;

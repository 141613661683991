import React, {useState, useEffect} from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Header from './components/Header';
import MobileNav from './components/MobileNav';
import HeroSection from './components/HeroSection';
import ServiceContainer from './components/ServiceContainer';
import MedicalCheckup from './components/MedicalCheckup';
import Carousel from './components/Carousel';
import DoctorList from './components/DoctorList';
import OtherDoctors from './components/OtherDoctors'; // Komponen untuk halaman dokter lainnya
import Footer from './components/Footer';
import SejarahPage from './pages/SejarahPage';
import VisimisiPage from './pages/VisimisiPage';
import ScrollToTop from './items/ScrollToTop';
import DoctorScheduleList from './components/DoctorScheduleList';
import AdminPanel from './components/AdminPanel';
import DoctorSchedulePage from './components/DoctorSchedulePage';
import LoginForm from './pages/LoginForm';

const App = () => {
  useEffect(() => {
    document.title = "RS Ar-Royyan"; // Judul default untuk halaman
  }, []);

  const images = [
    './assets//img/galerirsar1.jpeg',
    './assets//img/galerirsar2.jpeg',
    './assets//img/galerirsar3.jpeg',
    './assets//img/galerirsar4.jpeg',
  ];

  
  return (
    <Router>
      <div className="App bg-teal-100 mt-16">
        
        <Routes>
          <Route
            path="/"
            element={
              <>
              <ScrollToTop />
              <Header />
                <HeroSection />
                <ServiceContainer />
                <MedicalCheckup/>
                {/* Carousel */}
                <div className="mx-auto mt-20 p-4">
                  <Carousel images={images} />
                </div>
                {/* Konten halaman utama */}
                {/* <div className="container mx-auto mt-20 p-4">
                  <h1 className="text-3xl font-bold text-gray-900">Selamat Datang di Website Kami</h1>
                  <p className="mt-4 text-gray-600">Ini adalah contoh halaman yang menggunakan Tailwind CSS dengan React.</p>
                </div> */}
                {/* Daftar Dokter */}
                <div className='mb-24'>
                <h1 className="text-2xl lg:text-3xl font-bold text-teal-500 text-center mt-24">Rekomendasi Dokter</h1>
                <DoctorList />
                </div>
                <Footer />
              </>
            }
          />
          <Route path='/schedule' element={<DoctorScheduleList/>}></Route>
          <Route path='/jadwal' element={<DoctorSchedulePage/>}></Route>
          <Route path='/login' element={<LoginForm />}></Route>
          <Route path="/admin" element={<AdminPanel/>} />
          <Route path="/RekomendasiDokter" element={<OtherDoctors/>} />
          <Route path='/Sejarah' element={<SejarahPage/>} />
          <Route path='/VisiMisi' element={<VisimisiPage/>} />
        </Routes>
        <MobileNav />
      </div>
    </Router>
  );
}

export default App;

import { useState } from "react";
import { Link } from "react-router-dom";
import Dropdown from "../items/Dropdown";
import ScrollButton from "../items/ScrollButton";

const NavbarMobile = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className="md:hidden">
        <button
          onClick={() => setIsOpen(!isOpen)}
          type="button"
          className="text-gray-600 hover:text-gray-800 focus:outline-none"
        >
          <svg
            className="h-6 w-6"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 6H20M4 12H20M4 18H20"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <div className={`${isOpen ? "block" : "hidden"} bg-white border-t border-gray-200 rounded`}>
          <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
            <Link to="/" className="block text-teal-600 hover:text-gray-800 px-3 py-2 rounded-md font-semibold">Beranda</Link>
            <Dropdown title="PROFIL">
          <Link to="/Sejarah" className="block px-4 py-2 text-gray-800 rounded-lg hover:bg-teal-200">Sejarah</Link>
          <Link to="/VisiMisi" className="block px-4 py-2 text-gray-800 rounded-lg hover:bg-teal-200">Visi & Misi</Link>
        </Dropdown>
            <Link to="/PaketMCU" className="block text-teal-600 hover:text-gray-800 px-3 py-2 rounded-md font-semibold">Paket MCU</Link>
            <Link to="/RekomendasiDokter" className="block text-teal-600 hover:text-gray-800 px-3 py-2 rounded-md font-semibold">Cari Dokter</Link>
            <ScrollButton targetId="phoneNumber">
        <span className="block text-teal-600 hover:text-gray-800 px-3 py-2 rounded-md font-semibold">
          Kontak
        </span>
      </ScrollButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default NavbarMobile;

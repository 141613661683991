import React from 'react';

const HeroImage = () => {
  return (
    <div className="mt-0 md:mt-0 w-475 h-290 flex justify-end">
      <img className="object-contain w-32 h-auto md:w-full md:h-max" src="/assets/img/docternobg.png" alt="doctor" />
    </div>
  );
};

export default HeroImage;

import React, { useState } from 'react';
import ServiceMcu from './ServiceMcu';
import ModalPaket from './ModalPaket';
import { IoMdStar } from 'react-icons/io';

const MedicalCheckup = () => {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({});

  const handleOpenModal = (content) => {
    setModalContent(content);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="bg-teal-100 pb-12">
      <div className="flex flex-col items-center justify-center mx-auto md:mt-8 mb-8">
      
        <h1 className="flex px-8 mb-4 text-2xl font-semibold">
          <IoMdStar className='items-center justify-center mr-2 text-yellow-400 text-3xl'  />
          Paket Medical Check-Up
        </h1>
      </div>
      <div className="flex flex-wrap justify-center px-12">
        {/* Paket Silver */}
        <ServiceMcu
          title="Paket Silver"
          price="Rp. 880.500,-"
          image="/assets/img/mcu1.jpg"
          details="8 Tes *"
          onOpenModal={() => handleOpenModal({
            id: 'paket-silver',
            title: 'Paket Silver',
            features1: ['Administrasi MCU', 'Konsul Dokter Umum', 'Pemeriksaan Darah', 'Tes Buta Warna', 'Rontgen Dada/PA', 'Fungsi Ginjal', 'Fungsi Hati', 'Tes Urine' ],
            features2: ['EKG', 'Cek Gula Darah Sewaktu', 'Cek Asam Urat', 'Cek Kolestrol', 'Cek Hepatitis B', 'Cek Golongan Darah', 'Konsul Dokter Spesialis PDL', 'Konsul Dokter Spesialis THT-KL', 'Konsul Dokter Gigi']
          })}
        />
        {/* Paket Gold */}
        <ServiceMcu
          title="Paket Gold"
          price="Rp. 1.880.500,-"
          image="/assets/img/mcu1.jpg"
          details="12 Tes *"
          onOpenModal={() => handleOpenModal({
            id: 'paket-gold',
            title: 'Paket Gold',
            features1: ['Administrasi MCU', 'Konsul Dokter Umum', 'Pemeriksaan Darah', 'Tes Buta Warna', 'Rontgen Dada/PA', 'Fungsi Ginjal', 'Fungsi Hati', 'Tes Urine', 'EKG', 'Cek Gula Darah Sewaktu', 'Cek Asam Urat', 'Cek Kolestrol'],
            features2: ['Cek Hepatitis B', 'Cek Golongan Darah', 'Konsul Dokter Spesialis PDL', 'Konsul Dokter Spesialis THT-KL', 'Konsul Dokter Gigi']
          })}
        />
        {/* Paket Platinum */}
        <ServiceMcu
          title="Paket Platinum"
          price="Rp. 2.380.500,-"
          image="/assets/img/mcu1.jpg"
          details="16 Tes *"
          onOpenModal={() => handleOpenModal({
            id: 'paket-platinum',
            title: 'Paket Platinum',
            features1: ['Administrasi MCU', 'Konsul Dokter Umum', 'Pemeriksaan Darah', 'Tes Buta Warna', 'Rontgen Dada/PA', 'Fungsi Ginjal', 'Fungsi Hati', 'Tes Urine', 'EKG', 'Cek Gula Darah Sewaktu', 'Cek Asam Urat', 'Cek Kolestrol','Cek Hepatitis B', 'Cek Golongan Darah', 'Konsul Dokter Spesialis PDL', 'Konsul Dokter Spesialis THT-KL', 'Konsul Dokter Gigi' ],
            features2: []
          })}
        />
      </div>

      {showModal && (
        <ModalPaket
          id={modalContent.id}
          title={modalContent.title}
          features1={modalContent.features1}
          features2={modalContent.features2}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default MedicalCheckup;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import 'moment/locale/id'; 
import Header from './Header';
import Footer from './Footer';

moment.locale('id');

const DoctorSchedulePage = () => {
    useEffect(() => {
        document.title = "Jadwal Dokter Rumah Sakit Ar-Royyan"; // Mengatur judul halaman saat komponen dimuat
      }, []); 

    const [schedules, setSchedules] = useState([]);
    const [lastUpdated, setLastUpdated] = useState(null);

    useEffect(() => {
        // Memuat daftar jadwal dokter dan waktu terakhir diupdate dari backend saat komponen dimuat
        fetchDoctorSchedules();
        fetchLastUpdated();
    }, []);

    const fetchDoctorSchedules = async () => {
        try {
            const response = await axios.get('http://localhost:4000/jadwal-dokter');
            setSchedules(response.data);
        } catch (error) {
            console.error('Error fetching doctor schedules:', error);
        }
    };

    const fetchLastUpdated = async () => {
        try {
            const response = await axios.get('http://localhost:4000/last-updated');
            setLastUpdated(response.data.last_updated);
        } catch (error) {
            console.error('Error fetching last updated time:', error);
        }
    };

    return (
        <>
        <Header />
        <div className="mx-auto w-full pt-8 mb-48">
            <h1 className="text-3xl font-bold mb-4 text-center pb-8">Jadwal Dokter</h1>

            {/* Menampilkan waktu terakhir diupdate */}
            {lastUpdated && (
                <div className="text-center mb-8 text-blue-600 font-semibold text-lg">
                    Terakhir diupdate: {moment(lastUpdated).format('dddd, DD-MM-YYYY HH:mm')}
                </div>
            )}
            
            {/* Daftar jadwal dokter */}
            <ul>
                {schedules.map(schedule => (
                    <li key={schedule.id} className="flex my-4 p-4 border-2 border-slate-700 rounded-2xl mx-auto w-96 text-center bg-white justify-between items-center">
                        <div className='text-left w-1/2 text-sm font-semibold'>{schedule.dokter}</div>
                        <div className='font-semibold text-green-600'>
                            {moment(schedule.jam_mulai, 'HH:mm:ss').format('HH:mm')} - {moment(schedule.jam_selesai, 'HH:mm:ss').format('HH:mm')}
                        </div>
                    </li>
                ))}
            </ul>
        </div>
        <Footer />
        </>
    );
};

export default DoctorSchedulePage;

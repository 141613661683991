import React from 'react';

const DoctorCard = ({ name, specialty, image, schedule }) => {
  return (
    <div className="p-2 w-72 h-80 bg-white border-2 border-teal-400 rounded-2xl shadow-2xl shadow-teal-400 hover:scale-[1.02] focus:scale-[1.02] active:scale-100">
      <div className="flex items-center mb-4 px-2">
        <img src={image} alt={`Foto ${name}`} className="w-16 h-16 rounded-full object-cover mr-4" />
        <div>
          <h2 className="text-xs lg:text-sm font-semibold">{name}</h2>
          <p className="text-xs lg:text-sm text-gray-500">{specialty}</p>
        </div>
      </div>
      <div>
        <div className="px-4 md:px-8 lg:mx-0 h-40">
          <h3 className="text-md font-semibold mb-2">Jadwal Praktek</h3>
          <ul className='text-xs'>
            {schedule.map((item, index) => (
              <li key={index} className="flex justify-between border-b-2">
                <span>{item.day}</span>
                <span className="ml-auto">{item.time}</span>
              </li>
            ))}
          </ul>
        </div>
        <div className="mt-4 mb-1 mx-4 px-2">
          <p className="text-xs text-red-500">*jadwal sewaktu-waktu dapat berubah</p>
          <p className="text-xs text-gray-600">info lebih lanjut hubungi 0711-581976</p>
        </div>
      </div>
    </div>
  );
};

export default DoctorCard;

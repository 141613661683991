import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment'; // Tambahkan moment.js untuk format tanggal
import 'moment/locale/id'; 
import Header from './Header';
import Footer from './Footer';

moment.locale('id');

const AdminPanel = () => {
    useEffect(() => {
        document.title = "Admin Page Rumah Sakit Ar-Royyan"; // Mengatur judul halaman saat komponen dimuat
      }, []); 

    const [newSchedule, setNewSchedule] = useState({
        dokter: '',
        jam_mulai: '',
        jam_selesai: ''
    });

    const [schedules, setSchedules] = useState([]);
    const [lastUpdated, setLastUpdated] = useState(null); // Tambahkan state untuk menyimpan waktu terakhir diupdate

    useEffect(() => {
        // Memuat daftar jadwal dokter dari backend saat komponen dimuat
        fetchDoctorSchedules();
    }, []);

    const fetchDoctorSchedules = async () => {
        try {
            const response = await axios.get('http://localhost:4000/jadwal-dokter');
            setSchedules(response.data);
            const latestUpdate = response.data.reduce((latest, schedule) => {
                return new Date(schedule.last_updated) > new Date(latest.last_updated) ? schedule : latest;
            }, { last_updated: null });
            setLastUpdated(latestUpdate.last_updated);
        } catch (error) {
            console.error('Error fetching doctor schedules:', error);
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewSchedule(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post('http://localhost:4000/jadwal-dokter', newSchedule);
            console.log('New schedule added:', response.data);
            setNewSchedule({
                dokter: '',
                jam_mulai: '',
                jam_selesai: ''
            });
            fetchDoctorSchedules(); // Memuat kembali daftar jadwal setelah penambahan
        } catch (error) {
            console.error('Error adding new schedule:', error);
        }
    };

    const handleDeleteSchedule = async (id) => {
        try {
            const response = await axios.delete(`http://localhost:4000/jadwal-dokter/${id}`);
            console.log('Schedule deleted:', response.data);
            fetchDoctorSchedules(); // Memuat kembali daftar jadwal setelah penghapusan
        } catch (error) {
            console.error('Error deleting schedule:', error);
        }
    };

    return (
        <>
        <Header />
            <div className="mx-auto">
                <h2 className="text-3xl font-bold mb-4 text-center pt-8">Admin</h2>
                <div className="text-center mb-4 text-gray-600">
                    {lastUpdated && (
                        <div>
                            Terakhir diupdate: {moment(lastUpdated).format('dddd, DD-MM-YYYY HH:mm')}
                        </div>
                    )}
                </div>
                {/* Form untuk menambah jadwal dokter */}
                <div className=''>
                <form onSubmit={handleSubmit} className="flex flex-col lg:flex-row items-center my-4 gap-4 py-8 justify-center">
                    <input type="text" name="dokter" placeholder="Nama Dokter" value={newSchedule.dokter} onChange={handleInputChange} className="mr-2 p-2 border-2 border-slate-700 rounded focus:bg-blue-100 hover:scale-[1.02] focus:scale-[1.02] active:scale-100" required />
                    <input type="text" name="jam_mulai" placeholder="Jam Mulai" value={newSchedule.jam_mulai} onChange={handleInputChange} className="mr-2 p-2 border-2 border-slate-700 rounded focus:bg-blue-100 hover:scale-[1.02] focus:scale-[1.02] active:scale-100" required />
                    <input type="text" name="jam_selesai" placeholder="Jam Selesai" value={newSchedule.jam_selesai} onChange={handleInputChange} className="mr-2 p-2 border-2 border-slate-700 rounded focus:bg-blue-100 hover:scale-[1.02] focus:scale-[1.02] active:scale-100" required />
                    <button type="submit" className=" hover:bg-blue-700 focus:bg-red-400 bg-blue-300 py-2 px-4 rounded-xl text-black border-2 border-blue-500 hover:scale-[1.12] focus:scale-[1.02] active:scale-100">Tambah Jadwal</button>
                </form>
                </div>
            </div>

            <div className="container mx-auto pb-32">
                <h1 className="text-3xl font-bold mb-4 text-center">Jadwal Dokter</h1>

                {/* Daftar jadwal dokter */}
                <ul>
      {schedules.map(schedule => (
        <li key={schedule.id} className="flex my-4 p-4 border-2 border-slate-700 rounded-2xl mx-auto w-96 text-center bg-white justify-between items-center">
          <div className='text-left w-1/2 text-sm font-semibold'>{schedule.dokter}</div>
          <div className='font-semibold text-green-600'>
            {moment(schedule.jam_mulai, 'HH:mm:ss').format('HH:mm')} - {moment(schedule.jam_selesai, 'HH:mm:ss').format('HH:mm')}
          </div>
          <button onClick={() => handleDeleteSchedule(schedule.id)} className="bg-red-500 hover:bg-red-700 text-white py-1 px-2 mt-2 rounded">Hapus</button>
        </li>
      ))}
    </ul>
            </div>
            <Footer/>
        </>
    );
};

export default AdminPanel;

// LoginForm.jsx
import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';

const LoginForm = () => {
    useEffect(() => {
        document.title = "Login Page Rumah Sakit Ar-Royyan"; // Mengatur judul halaman saat komponen dimuat
      }, []); 

    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('http://localhost:4000/login', {
                username,
                password,
            });

            if (response.status === 200) {
                localStorage.setItem('token', response.data.token);
                navigate('/admin');
            }
        } catch (error) {
            setError('Login failed. Please check your credentials.');
        }
    };

    return (
        <>
        <Header />
        <div className='flex flex-col items-center justify-center px-6 py-24 mx-auto mb-80'>
            <div className='w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 '>
            <div class="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h2 className='text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl'>ADMIN LOGIN</h2>
            <form onSubmit={handleSubmit} className='space-y-4 md:space-y-6' >
                <div>
                    <label className='block mb-2 text-sm font-medium text-gray-900'>Username:</label>
                    <input
                        type="text"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        className='bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                    />
                </div>
                <div className='pb-8'>
                    <label className='block mb-2 text-sm font-medium text-gray-900'>Username:</label>
                    <label className=''>Password:</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className='bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                    />
                </div>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                <button type="submit" className='w-full text-gray-900 bg-blue-300 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center'>Login</button>
            </form>
            </div>
            </div>
        </div>
        <Footer/>
        </>

        
    );
};

export default LoginForm;

import React from 'react';
import HeroImage from './HeroImage';

const HeroSection = () => {
  return (
    <section
      className=" md:flex shadow-md bg-cover bg-center"
      style={{ backgroundImage: "url(/assets/img/herobackground.jpg)" }}
    >
      <div className="md:flex lg:flex lg:mx-auto lg:px-64 justify-center md:pb-8 md:mt-20">
        <div className="md:w-3/4 mt-8 pt-4 lg:mt-14 px-8 lg:pt-14">
          <h1 className="text- md:text-4xl font-bold mb-1 text-teal-600 text-left lg:text-5xl tracking-widest">
            Kesembuhan Anda Adalah Prioritas Kami
          </h1>
          <p className="text-xs md:text-sm lg:text-lg text-slate-800 tracking-widest">
            "Berkomitmen memberikan perawatan terbaik untuk kesembuhan Anda. Kami siap memberikan layanan kesehatan yang penuh perhatian dan berkualitas."
          </p>
        </div>
        <HeroImage />
      </div>
    </section>
  );
};

export default HeroSection;

import React from 'react';
import { Link } from 'react-router-dom';
import DoctorCard from './DoctorCard';

const doctors = [
  {
    name: 'dr. Ari Fibrianto, Sp.A',
    specialty: 'Spesialis Ibu & Anak',
    image: '/assets/img_dokter/dr.ari.jpeg',
    schedule: [
      { day: 'Senin', time: '08.00 - 09.00' },
      { day: 'Rabu', time: '08.00 - 09.00' },
      { day: 'Kamis', time: '08.00 - 09.00' },
    ]
  },
  {
    name: 'dr. Reysginawathie, Sp.PD',
    specialty: 'Spesialis Penyakit Dalam',
    image: '../assets/img_dokter/dr.riris.jpeg',
    schedule: [
      { day: 'Senin', time: '08.00 - 10.00' },
      { day: 'Selasa', time: '08.00 - 10.00' },
      { day: 'Rabu', time: '08.00 - 10.00' },
      { day: 'Kamis', time: '08.00 - 10.00' },
      { day: 'Jumat', time: '08.00 - 10.00' },
      
    ]
  },
  {
    name: 'dr. Boby Febriansyah, Sp.B',
    specialty: 'Spesialis Bedah',
    image: '../assets/img_dokter/dr.boby.jpeg',
    schedule: [
      { day: 'Senin', time: '12.00 - 15.00' },
      { day: 'Selasa', time: '12.00 - 15.00' },
      { day: 'Kamis', time: '12.00 - 15.00' },
      { day: 'Jumat', time: '10.00 - 12.00' },
    ]
  },
  {
    name: 'dr. Rivai Baharuddin Yudha Putra, Sp.OG',
    specialty: 'Spesialis Obgyn',
    image: '../assets/img_dokter/dr.rivai.jpeg',
    schedule: [
      { day: 'Senin', time: '10.00 - 12.00' },
      { day: 'Selasa', time: '09.00 - 11.00' },
      { day: 'Rabu', time: '09.00 - 11.00' },
      { day: 'Jumat', time: '09.00 - 11.00' },
    ]
  },
  {
    name: 'dr. Reti Anggraeni, Sp.D.V',
    specialty: 'Spesialis Kulit & Kelamin',
    image: '../assets/img_dokter/dr.reti.jpeg',
    schedule: [
      { day: 'Senin', time: '08.00 - 10.00' },
      { day: 'Selasa', time: '08.00 - 10.00' },
      { day: 'Rabu', time: '08.00 - 10.00' },
      { day: 'Kamis', time: '08.00 - 10.00' },
      { day: 'Jumat', time: '08.00 - 10.00' },
    ]
  },
  {
    name: 'dr. Syafran Rasyidi, Sp.PD',
    specialty: 'Spesialis Penyakit Dalam',
    image: '../assets/img_dokter/dr.syafran.jpeg',
    schedule: [
      { day: 'Senin', time: '08.00 - 10.00 & 14.00 - 16.00' },
      { day: 'Selasa', time: '08.00 - 10.00 & 14.00 - 16.00' },
      { day: 'Rabu', time: '08.00 - 10.00 & 14.00 - 16.00' },
      { day: 'Kamis', time: '08.00 - 10.00 & 14.00 - 16.00' },
      { day: 'Jumat', time: '08.00 - 10.00 & 14.00 - 16.00' },
      { day: 'Sabtu', time: '09.00 - 11.00' },
    ]
  },
  {
    name: 'dr. Sheila Stephanie Chandra, Sp.N',
    specialty: 'Spesialis Saraf',
    image: '../assets/img_dokter/dr.sheila.jpeg',
    schedule: [
      { day: 'Senin', time: '15.30 - Selesai' },
      { day: 'Selasa', time: '15.30 - Selesai' },
      { day: 'Rabu', time: '15.30 - Selesai' },
      { day: 'Kamis', time: '15.30 - Selesai' },
    ]
  },
  {
    name: 'dr. Widya Adiarti Minwal, Sp. THT-KL',
    specialty: 'Spesialis THT',
    image: '../assets/img_dokter/dr.widya.jpeg',
    schedule: [
      { day: 'Senin', time: '10.00 - 12.00' },
      { day: 'Selasa', time: '10.00 - 12.00' },
    ]
  },
  // Tambahkan dokter lainnya di sini
];

const DoctorList = () => {
  return (
    <div className="container mx-auto mt-4 lg:mt-12 p-4 flex flex-wrap justify-center gap-8">
      {doctors.map((doctor, index) => (
        <DoctorCard
          key={index}
          name={doctor.name}
          specialty={doctor.specialty}
          image={doctor.image}
          schedule={doctor.schedule}
        />
      ))}
      <div className="w-full flex justify-center mt-8">
        <Link
          to="/RekomendasiDokter"
          className="inline-flex items-center px-6 py-3 text-sm font-medium text-center text-white bg-teal-400 rounded-lg hover:bg-teal-500 focus:ring-4 focus:outline-none focus:ring-teal-700 hover:scale-[1.08] focus:scale-[1.08] active:scale-100"
        >
          Lihat Dokter Lainnya
          <svg className="rtl:rotate-180 w-4 h-4 ml-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 8l4 4m0 0l-4 4m4-4H3" />
          </svg>
        </Link>
      </div>
    </div>

  );
};

export default DoctorList;

import React from 'react';
import { FaExternalLinkAlt } from 'react-icons/fa';

const ButtonSelengkapnya = ({ onClick }) => {
  return (
    <button onClick={onClick} className="flex items-center justify-center text-gray-800 hover:underline focus:text-blue-600 hover:text-blue-600 focus:underline font-medium rounded-2xl text-sm py-2.5 text-center">
      Selengkapnya
      <FaExternalLinkAlt className='ml-1 text-xs' />
    </button>
  );
};

export default ButtonSelengkapnya;

// ScrollButton.jsx
import React from 'react';

const ScrollButton = ({ targetId, children }) => {
  const scrollToElement = () => {
    const element = document.getElementById(targetId);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <button
      className=""
      onClick={scrollToElement}
    >
      {children}
    </button>
  );
};

export default ScrollButton;

import React from 'react';
import { Link } from 'react-router-dom';
import Dropdown from '../items/Dropdown';
import NavbarMobile from './NavbarMobile';
import ScrollButton from '../items/ScrollButton';

const Header = () => {
  return (
    <nav className="bg-white fixed h-16  w-full z-50 top-0 start-0 border-gray-200 dark:border-gray-600 opacity-90">
          <div className="max-w-7xl mx-auto px-4">
            <div className="flex flex-row justify-between py-2 pt-4 md:items-center md:pt-2">
            
      <div className='justify-self-center md:hidden'>
      <NavbarMobile/>
      </div>
      <div className="flex flex-row">
      <Link to="/" className="text-lg font-bold text-gray-800">
        <img
          src="/assets/img/LogoRsarFix.svg"
          className="w-28 h-8 ml-auto lg:w-36 lg:h-12"
          alt="RsAR Logo"
        />
      </Link>
      </div>
    
              <div className="hidden md:block">
                <Link to="/" className="text-teal-600 hover:text-gray-800 px-3 font-semibold">Beranda</Link>
                <Dropdown title="PROFIL">
                  <Link to="/Sejarah" className="block px-4 py-2 text-gray-800 rounded-lg hover:bg-teal-200">Sejarah</Link>
                  <Link to="/VisiMisi" className="block px-4 py-2 text-gray-800 rounded-lg hover:bg-teal-200">Visi & Misi</Link>
                </Dropdown>
                <Link to="/jadwal" className="text-teal-600 hover:text-gray-800 px-3 font-semibold">Jadwal Dokter</Link>
                <Link to="/RekomendasiDokter" className="text-teal-600 hover:text-gray-800 px-3 font-semibold">Cari Dokter</Link>
                {/* <Link to="#phoneNumber" className="text-teal-600 hover:text-gray-800 px-3 font-semibold">Kontak</Link> */}
                <ScrollButton targetId="phoneNumber">
        <span className="block text-teal-600 hover:text-gray-800 px-3 py-2 rounded-md font-semibold">
          Kontak
        </span>
      </ScrollButton>
      <Link to='/login' class="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-cyan-500 to-blue-500 group-hover:from-cyan-500 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-cyan-200 dark:focus:ring-cyan-800">
<span class="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-blue-600 rounded-md group-hover:bg-opacity-0">
Login
</span>
</Link>
              </div>
            </div>
          </div>
          
          
    </nav>
    
  );
};
// const MobileMenu = () => {
//   return (
//     <div id="mobile-menu" className="md:hidden bg-teal-100 hidden px-4 py-4">
//       <a href="#" className="block py-2 px-2 text-gray-600 hover:bg-teal-400 rounded-xl">Beranda</a>
//       <div className="relative">
//         <Link to="/" id="mobileProfile" className="flex py-2 px-2 items-center text-gray-600 hover:bg-teal-400 rounded-xl">
//           Profil
//           <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
//             <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
//           </svg>
//         </Link>
//         <div id="mobileProfileDropdown" className="hidden">
//           <a href="src/sejarah.html" className="block px-4 py-1 text-sm text-gray-800 hover:bg-teal-400 rounded-xl">Sejarah</a>
//           <a href="src/visimisi.html" className="block px-4 py-1 text-sm text-gray-800 hover:bg-teal-400 rounded-xl">Visi & Misi</a>
//         </div>
//       </div>
//       <a href="#" className="block py-2 px-2 text-gray-600 hover:bg-teal-400 rounded-xl">Galeri</a>
//       <a href="#" className="block py-2 px-2 text-gray-600 hover:bg-teal-400 rounded-xl">Kontak</a>
//     </div>
//   );
// };

export default Header;

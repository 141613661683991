// HistorySection.jsx
import React, {useEffect} from 'react';
import Breadcrumb from '../components/Breadcrumb';
import Footer from '../components/Footer';
import ScrollToTop from '../items/ScrollToTop';
import Header from '../components/Header';

const VisimisiPage = ({title}) => {
  useEffect(() => {
    document.title = "Visi & Misi Rumah Sakit Ar-Royyan"; // Mengatur judul halaman saat komponen dimuat
  }, []); 
  return (
    <>
    <Header />
    <ScrollToTop />
    <Breadcrumb judul="Visi & Misi" />
    <div class="mx-12 md:mx-60 pt-8 pb-80 text-center text-2xl">
    <h1 class="text-3xl font-semibold">Motto</h1>
    <p class="pt-4 italic">
        "Kesembuhan Anda Adalah Prioritas Kami"
    </p>
    <h1 class="text-3xl pt-12 font-semibold">Visi</h1>
    <p class="pt-4">"Menjadikan rumah sakit unggulan di Ogan Ilir yang memberikan pelayanan prima, berkuallitas, serta menjadi pilihan masyarakat"</p>
    <h1 class="text-3xl pt-12 font-semibold">Misi</h1>
    <ul class="list-disc list-inside mt-4 space-y-2 text-left">
        <li class="text-gray-700">Memberikan pelayanan prima, aman, bermutu bagi masyarakat.</li>
        <li class="text-gray-700">Menyelenggarakan pelayanan rujukan yang berfungsi sebagai pusat rujukan lanjutan bagi masyarakat ogan ilir.</li>
        <li class="text-gray-700">Mengembangkan SDM yang profesional serta sarana dan prasarana yang berkualitas.</li>
        <li class="text-gray-700">Mengembangkan pendidikan dan pelatihan yang bertujuan untuk meningkatkan kualitas pelayanan dan kesejahteraan pegawai.</li>
    </ul>
    </div>
    <Footer />
    </>
  );
};

export default VisimisiPage;

import React from 'react';
import ButtonPesan from './ButtonPesan';
import ButtonSelengkapnya from './ButtonSelengkapnya';

const ServiceMcu = ({ title, price, image, details, onOpenModal }) => {
  return (
    <div className="mx-8 mb-4 my-6 max-w-sm lg:w-72 lg:mb-0 lg:pb-2 bg-white border border-teal-300 rounded-2xl hover:border-teal-400 hover:border-2 hover:shadow-2xl hover:shadow-teal-400 hover:scale-[1.02] focus:scale-[1.02] active:scale-100">
      <a href="#">
        <img className="rounded-t-2xl hover:opacity-80" src={image} alt="" />
      </a>
      <div className="px-4 py-2">
        <h1 className="text-xl font-semibold tracking-tight text-gray-700 dark:text-gray-800">{title}</h1>
        <p className="font-normal text-gray-700 dark:text-gray-400 hidden">{price}</p>
        <p className="text-gray-600 text-xs mb-3">{details}</p>
        <div className="flex justify-between md:items-center mt-14">
          <ButtonPesan url="https://wa.me/6281271294991" />
          <ButtonSelengkapnya onClick={onOpenModal} />
        </div>
      </div>
    </div>
  );
};

export default ServiceMcu;

import React from 'react';
import ServiceCard from './ServiceCard';

const ServiceContainer = () => {
  return (
    <div className="mb-16 px-4 md:-mt-14 md:mx-8 lg:-mt-24 -mt-10 z-20 md:px-12 md:pt-4 lg:mx-32">
      <div className="bg-green-100 px-4 pb-4 lg:mx-32 md:mx-8 z-20 rounded-2xl border-green-300 border-2 items-center justify-center shadow-2xl shadow-green-300">
        <div className="px-1">
          <h1 className="font-semibold text-teal-700 text-xl pt-4 tracking-wider">Pusat Layanan</h1>
          <p className="text-slate-800 text-xs md:text-lg">Dengan tim medis yang berpengalaman dan fasilitas modern, kami siap memberikan layanan terbaik untuk memastikan Anda mendapatkan perawatan yang komprehensif dan efektif.</p>
        </div>
        <div className="flex flex-nowrap lg:flex-wrap p-6 px-6 overflow-x-auto space-x-8 lg:items-center lg:justify-center">
          <ServiceCard imageSrc="/assets/layananpoli/saraf.png" altText="Poli Saraf" serviceName="Poli Saraf" />
          <ServiceCard imageSrc="/assets/layananpoli/penyakitdalam.png" altText="Poli PDL" serviceName="Poli PDL" />
          <ServiceCard imageSrc="/assets/layananpoli/mata.png" altText="Poli Mata" serviceName="Poli Mata" />
          <ServiceCard imageSrc="/assets/layananpoli/bedah.png" altText="Poli Bedah" serviceName="Poli Bedah" />
          <ServiceCard imageSrc="/assets/layananpoli/ibuanak.png" altText="Poli Ibu&Anak" serviceName="Poli Ibu&Anak" />
          <ServiceCard imageSrc="/assets/layananpoli/kulit.png" altText="Poli Kulit&Kelamin" serviceName="Poli Kulit&Kelamin" />
          <ServiceCard imageSrc="/assets/layananpoli/gigi.png" altText="Poli Gigi" serviceName="Poli Gigi" />
          <ServiceCard imageSrc="/assets/layananpoli/hd.png" altText="Hemodialisa" serviceName="Hemodialisa" />
          <ServiceCard imageSrc="/assets/layananpoli/obgyn.png" altText="Poli Obgyn" serviceName="Poli Obgyn" />
          <ServiceCard imageSrc="/assets/layananpoli/geriatri.png" altText="Poli Geriatri" serviceName="Poli Geriatri" />
        </div>
      </div>
    </div>
  );
};

export default ServiceContainer;

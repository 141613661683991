import React, { useState } from 'react';

const Dropdown = ({ title, children }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  return (
    <div className="relative inline-block text-left" onMouseLeave={closeDropdown}>
      <button
        onClick={toggleDropdown}
        className="inline-flex justify-center items-center w-full rounded-md px-4 font-semibold text-teal-600 hover:text-gray-800"
        id="menu-button"
        aria-expanded={isOpen ? 'true' : 'false'}
        aria-haspopup="true"
      >
        {title}
        <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
           <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7"></path>
         </svg>
      </button>

      {isOpen && (
        <div
          className="origin-top-right absolute right-0 mt-0 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabIndex="-1"
        >
          <div className="py-1" role="none">
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;

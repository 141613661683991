import React from 'react';
import { SiTicktick } from 'react-icons/si';
import { IoMdStar } from 'react-icons/io';

const ModalPaket = ({ id, title, features1, features2, onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white p-8 rounded-lg shadow-lg w-96">
      
        <h2 className=" flex text-2xl font-bold mb-4">
        <IoMdStar className='flex items-center justify-center mr-2 text-yellow-400 text-3xl'  />
        {title}</h2>
        <ul className="list-disc list-inside text-left mb-4">
          {features1.map((feature, index) => (
            <li key={`${id}-feature1-${index}`} className="flex items-center text-gray-600">
              <SiTicktick className="mr-2 text-green-400" />
              {feature}
            </li>
          ))}
          {features2.map((feature, index) => (
            <li key={`${id}-feature2-${index}`} className="flex items-center text-gray-300">
              <SiTicktick className="mr-2 text-gray-300" />
              {feature}
            </li>
          ))}
        </ul>
        <button
          className="mt-4 px-4 py-2 bg-teal-500 text-white rounded hover:bg-teal-600 focus:outline-none focus:bg-teal-600"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default ModalPaket;

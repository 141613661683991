import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Breadcrumb = ({ judul }) => {
  const location = useLocation();
  
  // Debugging
  console.log('Current path:', location.pathname);
  console.log('Current page:', judul);

  return (
    <div className='pt-12 bg-teal-300 pb-10'>
      <h1 className='flex items-center justify-center font-semibold text-teal-600 text-3xl'>{judul}</h1>
      <nav className="flex px-5 pt-4 items-center justify-center text-gray-700 rounded-lg" aria-label="Breadcrumb">
        <ol className="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
          <li className="inline-flex items-center">
            <Link to='/' className="inline-flex items-center text-sm font-medium text-gray-700 hover:text-teal-400 dark:text-gray-800 dark:hover:text-teal-400">
              <svg className="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z"/>
              </svg>
              Beranda
            </Link>
          </li>
          <li>
            <Link to='/profil' className="inline-flex items-center justify-center text-sm font-medium text-gray-900 hover:text-teal-400 dark:text-gray-800 dark:hover:text-teal-400">
              <svg className="rtl:rotate-180 block w-3 h-3 mx-2 text-gray-900" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
              </svg>
              Profil
            </Link>
          </li>
          <li>
            <h1 className="inline-flex items-center justify-center text-xs font-medium text-gray-500 dark:text-gray-500">
              <svg className="rtl:rotate-180 block w-3 h-3 mx-2 text-gray-900" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4"/>
              </svg>
              {judul}
            </h1>
          </li>
        </ol>
      </nav>
    </div>
  );
};

export default Breadcrumb;

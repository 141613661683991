import React from 'react';

const ServiceCard = ({ imageSrc, altText, serviceName }) => {
  return (
    <div className="flex flex-col items-center">
      <div className="w-16 h-16 rounded-full bg-teal-200">
        <img src={imageSrc} alt={altText} className="object-cover rounded-full border-slate-900 hover:opacity-80" />
      </div>
      <p className="text-sm text-center pt-3">{serviceName}</p>
    </div>
  );
};

export default ServiceCard;

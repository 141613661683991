// PhoneNumber.jsx
import React from 'react';

const PhoneNumber = ({ id, number }) => {
  return (
    <span className="text-lg text-teal-700 font-extrabold" id={id}>
      {number}
    </span>
  );
};

export default PhoneNumber;

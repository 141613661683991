// HistorySection.jsx
import React, {useEffect} from 'react';
import Breadcrumb from '../components/Breadcrumb';
import Footer from '../components/Footer';
import ScrollToTop from '../items/ScrollToTop';
import Header from '../components/Header';

const SejarahPage = () => {
  useEffect(() => {
    document.title = "Sejarah Rumah Sakit Ar-Royyan"; // Mengatur judul halaman saat komponen dimuat
  }, []); 
  return (
    <>
    <ScrollToTop />
    <Header />
    <Breadcrumb judul="Sejarah"/>
    <div className="mx-auto px-12 md:mx-60 pt-8 text-justify mb-12 lg:mb-96">
      <h1 className="text-4xl font-semibold text-center">Sejarah Awal</h1>
      <p className="pt-4 text-lg">Rumah Sait Ar-Royyan adalah salah satu rumah sakit swasta yang awalnya diberi nama Klinik Sehat Sejahtera (KSS). Kemudian berganti nama menjadi Klinik Utama Sehat Sejahtera. Namun seiring berjallannya waktu Klinik Utama Sehat Sejahtera terus berkembang dan mngalami peningkatan fasilitas dan pelayanan kesehatan baik dari ruangan medis maupun non medis dan sedang dalam tahap penyelesaian serta melengkapi peralatan-peralatan medis lainnya yang sesuai dengan standar rumah sakit pada umumnya.</p>
      <p className="pt-4 text-lg">Rumah Sakit Ar-Royyan terletak di jantung Kota Indralaya dengan lokasi yang sangat mudah dijangkau karena berada di Jalan Lintas Timur Palembang - Kayu Agung Km 33, yang berdiri sejak 01 Januari 2015 denggan fasilitas : Instalasi Rawat Jalan dengan dr. Spesialis Penyakit Dalam, dr. Spesialis Bedah, dr. Spesialis THT-KL, dr. Spesialis Saraf, dr. Spesialis Kebidanan dan Kandungan, dr. Spesialis Anak, dr. Spesialis Kulit dan Kelamin, dr. Spesialis Mata, Dokter Umum (dr.) dan Dokter Gigi Umum (drg.); Instalasi Rawat Inap dengan 114 TT, Instalasi Gawat Darurat 24 Jam, Instalasi ICU dengan 6 TT, Instalasi Hemodialisa, Instalasi Bedah Sentral dengan 2 Kamar Operasi, Instalasi Farmasi, Pelayanan KIA dan KB, Laboratorium, Radiologi, dan Ambulan.</p>
    </div>
    <Footer />
    </>
  );
};

export default SejarahPage;

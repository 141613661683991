import React from 'react';
import { Link } from 'react-router-dom';

const MobileNav = () => {
  return (
    <div className="fixed bottom-0 left-0 z-50 w-full h-14 bg-white border-t border-gray-200 dark:bg-gray-700 dark:border-gray-600 md:hidden">
      <div className="grid h-full max-w-lg grid-cols-4 mx-auto font-medium">
        <Link to='/' className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">
          <svg className="w-5 h-5 mb-1 text-gray-500 dark:text-gray-400 group-hover:text-teal-400 group-hover:scale-125 dark:group-hover:text-blue-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
            <path d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
          </svg>
          <span className="text-xs text-gray-500 dark:text-gray-400 group-hover:text-teal-400 group-hover:scale-125 dark:group-hover:text-blue-500">Beranda</span>
        </Link>
        <Link to='/jadwal' className="inline-flex flex-col items-center justify-center px-2 hover:bg-gray-50 dark:hover:bg-gray-800 group">
          <img src="/assets/img/doctor.png" className="w-5 h-5 mb-1 group-hover:text-teal-400 group-hover:scale-125" alt="" />
          <span className="text-xs text-gray-500 dark:text-gray-400 group-hover:text-teal-400 group-hover:scale-125 dark:group-hover:text-blue-500">Jadwal Dokter</span>
        </Link>
        <button type="button" className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">
          <img src="/assets/img/ambulance.png" className="w-5 h-5 mb-1 group-hover:text-teal-400 group-hover:scale-125" alt="" />
          <span className="text-xs text-gray-500 dark:text-gray-400 group-hover:text-teal-400 group-hover:scale-125 dark:group-hover:text-blue-500">Ambulance</span>
        </button>
        <a href="https://wa.me/6281271294991" target="_blank" className="inline-flex flex-col items-center justify-center px-5 hover:bg-gray-50 dark:hover:bg-gray-800 group">
          <img src="/assets/img/logowhatsapp.png" className="w-5 h-5 mb-1 group-hover:text-teal-400 group-hover:scale-125" alt="" />
          <span className="text-xs text-gray-500 dark:text-gray-400 group-hover:text-teal-400 group-hover:scale-125 dark:group-hover:text-blue-500">WhatsApp</span>
        </a>
      </div>
    </div>
  );
};

export default MobileNav;

import React, { useState, useEffect } from 'react';
import DoctorCard from './DoctorCard';
import Header from './Header';
import DropdownFilter from '../items/DropdownFilter';
import Footer from './Footer';

const doctors = [
  {
    name: 'dr. Ari Fibrianto, Sp.A',
    specialty: 'Spesialis Ibu & Anak',
    image: '/assets/img_dokter/dr.ari.jpeg',
    schedule: [
      { day: 'Senin', time: '08.00 - 09.00' },
      { day: 'Rabu', time: '08.00 - 09.00' },
      { day: 'Kamis', time: '08.00 - 09.00' },
    ]
  },
  {
    name: 'dr.H. Ahmad Restu Iman, Sp.PD,KKV, FINASIM',
    specialty: 'Spesialis Penyakit Dalam',
    image: '../assets/img_dokter/dr.iman.jpeg',
    schedule: [
      { day: 'Senin', time: '10.00 - 13.00' },
      { day: 'Selasa', time: '10.00 - 13.00' },
      { day: 'Rabu', time: '10.00 - 13.00' },
      { day: 'Kamis', time: '10.00 - 13.00' },
    ]
  },
  {
    name: 'dr. Reysginawathie, Sp.PD',
    specialty: 'Spesialis Penyakit Dalam',
    image: '../assets/img_dokter/dr.riris.jpeg',
    schedule: [
      { day: 'Senin', time: '08.00 - 10.00' },
      { day: 'Selasa', time: '08.00 - 10.00' },
      { day: 'Rabu', time: '08.00 - 10.00' },
      { day: 'Kamis', time: '08.00 - 10.00' },
      { day: 'Jumat', time: '08.00 - 10.00' },
      
    ]
  },
  {
    name: 'dr. Boby Febriansyah, Sp.B',
    specialty: 'Spesialis Bedah',
    image: '../assets/img_dokter/dr.boby.jpeg',
    schedule: [
      { day: 'Senin', time: '12.00 - 15.00' },
      { day: 'Selasa', time: '12.00 - 15.00' },
      { day: 'Kamis', time: '12.00 - 15.00' },
      { day: 'Jumat', time: '10.00 - 12.00' },
    ]
  },
  {
    name: 'dr. Rivai Baharuddin Yudha Putra, Sp.OG',
    specialty: 'Spesialis Obgyn',
    image: '../assets/img_dokter/dr.rivai.jpeg',
    schedule: [
      { day: 'Senin', time: '10.00 - 12.00' },
      { day: 'Selasa', time: '09.00 - 11.00' },
      { day: 'Rabu', time: '09.00 - 11.00' },
      { day: 'Jumat', time: '09.00 - 11.00' },
    ]
  },
  {
    name: 'dr. Reti Anggraeni, Sp.D.V',
    specialty: 'Spesialis Kulit & Kelamin',
    image: '../assets/img_dokter/dr.reti.jpeg',
    schedule: [
      { day: 'Senin', time: '08.00 - 10.00' },
      { day: 'Selasa', time: '08.00 - 10.00' },
      { day: 'Rabu', time: '08.00 - 10.00' },
      { day: 'Kamis', time: '08.00 - 10.00' },
      { day: 'Jumat', time: '08.00 - 10.00' },
    ]
  },
  {
    name: 'dr. Syafran Rasyidi, Sp.PD',
    specialty: 'Spesialis Penyakit Dalam',
    image: '../assets/img_dokter/dr.syafran.jpeg',
    schedule: [
      { day: 'Senin', time: '08.00 - 10.00 & 14.00 - 16.00' },
      { day: 'Selasa', time: '08.00 - 10.00 & 14.00 - 16.00' },
      { day: 'Rabu', time: '08.00 - 10.00 & 14.00 - 16.00' },
      { day: 'Kamis', time: '08.00 - 10.00 & 14.00 - 16.00' },
      { day: 'Jumat', time: '08.00 - 10.00 & 14.00 - 16.00' },
      { day: 'Sabtu', time: '09.00 - 11.00' },
    ]
  },
  {
    name: 'dr. Sheila Stephanie Chandra, Sp.N',
    specialty: 'Spesialis Saraf',
    image: '../assets/img_dokter/dr.sheila.jpeg',
    schedule: [
      { day: 'Senin', time: '15.30 - Selesai' },
      { day: 'Selasa', time: '15.30 - Selesai' },
      { day: 'Rabu', time: '15.30 - Selesai' },
      { day: 'Kamis', time: '15.30 - Selesai' },
    ]
  },
  {
    name: 'dr. Widya Adiarti Minwal, Sp. THT-KL',
    specialty: 'Spesialis THT',
    image: '../assets/img_dokter/dr.widya.jpeg',
    schedule: [
      { day: 'Senin', time: '10.00 - 12.00' },
      { day: 'Selasa', time: '10.00 - 12.00' },
    ]
  },
  {
    name: 'dr. Yesi Sefriani, Sp.A',
    specialty: 'Spesialis Kulit & Kelamin',
    image: '../assets/img_dokter/dr.yesi.jpeg',
    schedule: [
      { day: 'Selasa', time: '14.00 - 16.00' },
      { day: 'Kamis', time: '15.00 - 17.00' },
      { day: 'Jumat', time: '14.00 - 16.00' },
    ]
  },
  {
    name: 'dr. Augris Shandrianti, Sp.PD, FINASIM',
    specialty: 'Spesialis Penyakit Dalam',
    image: '../assets/img_dokter/dr.augris.jpeg',
    schedule: [
      { day: 'Senin', time: '08.00 - 10.00' },
      { day: 'Selasa', time: '10.00 - 12.00' },
      { day: 'Rabu', time: '09.00 - 12.00' },
      { day: 'Sabtu', time: '09.00 - 12.00' },
    ]
  },
  {
    name: 'dr. Edy Nur Rahman, Sp.PD, FINASIM',
    specialty: 'Spesialis Penyakit Dalam',
    image: '../assets/img_dokter/dr.edy.jpeg',
    schedule: [
      { day: 'Senin', time: '16.00 - 18.00' },
      { day: 'Selasa', time: '16.00 - 18.00' },
      { day: 'Rabu', time: '16.00 - 18.00' },
      { day: 'Kamis', time: '16.00 - 18.00' },
      { day: 'Jumat', time: '16.00 - 18.00' },
    ]
  },
  {
    name: 'dr. Mohammad Silahuddin, Sp.PD',
    specialty: 'Spesialis Penyakit Dalam',
    image: '../assets/img_dokter/dr.silahuddin.jpeg',
    schedule: [
      { day: 'Senin', time: '16.00 - 18.00' },
      { day: 'Selasa', time: '16.00 - 18.00' },
      { day: 'Rabu', time: '16.00 - 18.00' },
      { day: 'Kamis', time: '16.00 - 18.00' },
      { day: 'Jumat', time: '16.00 - 18.00' },
    ]
  },
  {
    name: 'dr. Eeng Yoneska, Sp.PD',
    specialty: 'Spesialis Penyakit Dalam',
    image: '../assets/img_dokter/dr.eeng.jpeg',
    schedule: [
      { day: 'Senin', time: '13.00 - 15.00' },
      { day: 'Selasa', time: '13.00 - 15.00' },
      { day: 'Rabu', time: '13.00 - 15.00' },
      { day: 'Kamis', time: '13.00 - 15.00' },
      { day: 'Jumat', time: '09.00 - 12.00' },
      { day: 'Sabtu', time: '13.00 - 15.00' },
    ]
  },
  {
    name: 'dr. Damal An Nasher, Sp.M',
    specialty: 'Spesialis Penyakit Dalam',
    image: '../assets/img_dokter/dr.damal.jpeg',
    schedule: [
      { day: 'Kamis', time: '08.00 - 10.00' },
      { day: 'Sabtu', time: '13.00 - 15.00' },
    ]
  },
  {
    name: 'dr. Rizky Noviyanti Dani, Sp.An',
    specialty: 'Spesialis Anastesi',
    image: '../assets/img_dokter/dr.Rizky.jpeg',
    schedule: [
    ]
  },
  {
    name: 'dr. Meita Ranika, Sp.Rad',
    specialty: 'Spesialis Radiologi',
    image: '../assets/img_dokter/dr.meita.jpeg',
    schedule: [
    ]
  },
  {
    name: 'dr. Melda Fio Flora BR Sijabat, M. Biomed, Sp.PK',
    specialty: 'Spesialis Radiologi',
    image: '../assets/img_dokter/dr.melda.jpeg',
    schedule: [
    ]
  },
  {
    name: 'dr. Septira Arindya Maharani',
    specialty: 'Dokter Umum',
    image: '../assets/img_dokter/dr.arin.jpeg',
    schedule: [
    ]
  },
  {
    name: 'dr. Azan Farid Wajdi',
    specialty: 'Dokter Umum',
    image: '../assets/img_dokter/dr.azan.jpeg',
    schedule: [
    ]
  },
  {
    name: 'dr. Indah Dwi Destiana',
    specialty: 'Dokter Umum',
    image: '../assets/img_dokter/dr.indah.jpeg',
    schedule: [
    ]
  },
  {
    name: 'dr. Pratiwi Karolina',
    specialty: 'Dokter Umum',
    image: '../assets/img_dokter/dr.tiwi.jpeg',
    schedule: [
    ]
  },
  {
    name: 'dr. Puspa Anggraini',
    specialty: 'Dokter Umum',
    image: '../assets/img_dokter/dr.puspa.jpeg',
    schedule: [
    ]
  },
  {
    name: 'dr. Fitri Amaliah',
    specialty: 'Dokter Umum',
    image: '../assets/img_dokter/dr.fitri.jpeg',
    schedule: [
    ]
  },
  {
    name: 'dr. Muhammad Dodi Fakhirin',
    specialty: 'Dokter Umum',
    image: '../assets/img_dokter/dr.dodi.jpeg',
    schedule: [
    ]
  },
  
  // Tambahkan dokter lainnya di sini
];

const OtherDoctors = () => {
  useEffect(() => {
    document.title = "Rekomendasi Dokter Rumah Sakit Ar-Royyan"; // Mengatur judul halaman saat komponen dimuat
  }, []); 

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedSpecialty, setSelectedSpecialty] = useState('Semua Dokter');

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // const handleSpecialtyChange = (e) => {
  //   setSelectedSpecialty(e.target.value);
  // };

  const handleSpecialtyChange = (specialty) => {
    setSelectedSpecialty(specialty);
  };
  const specialtiess = [
    'Semua Dokter',
    'Spesialis Ibu & Anak',
    'Spesialis Obgyn',
    'Spesialis THT',
    'Spesialis Penyakit Dalam',
    'Spesialis Kulit & Kelamin',
    'Spesialis Saraf',
    'Spesialis Bedah',
    'Dokter Umum',
    // Tambahkan spesialis lainnya di sini
  ];

  const filteredDoctors = doctors.filter((doctor) => 
    (selectedSpecialty === 'Semua Dokter' || doctor.specialty === selectedSpecialty) &&
    doctor.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <>
    <Header />
    <div className="mx-auto w-full p-4 pt-12 pb-24">
      <h1 className="text-3xl font-bold text-teal-500 pb-8 text-center">Rekomendasi Dokter</h1>
      <div className="mt-4 mb-8 lg:mb-16 flex flex-col md:flex-row justify-center px-20">
        <input
          type="text"
          placeholder="Cari Dokter..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="p-2 border-2 border-teal-400 focus:border-teal-500 rounded-md mb-4 md:mb-0 md:mr-4"
        />
        {/* <select
          value={selectedSpecialty}
          onChange={handleSpecialtyChange}
          className="p-2 border-2 border-teal-300 rounded-md"
        >
          <option value="">Pilih Spesialis</option>
          <option value="Spesialis Ibu & Anak">Spesialis Ibu & Anak</option>
          <option value="Spesialis Kandungan">Spesialis Kandungan</option>
          <option value="Spesialis THT">Spesialis THT</option>
          <option value="Spesialis Penyakit Dalam">Spesialis Penyakit Dalam</option>
        </select> */}
        <DropdownFilter
        title={selectedSpecialty || "Pilih Spesialis"}
        items={specialtiess}
        onSelect={handleSpecialtyChange}
      />
      </div>
      <div className="flex flex-wrap justify-center gap-8 lg:mx-48">
        {filteredDoctors.length > 0 ? (
          filteredDoctors.map((doctor, index) => (
            <DoctorCard
              key={index}
              name={doctor.name}
              specialty={doctor.specialty}
              image={doctor.image}
              schedule={doctor.schedule}
            />
          ))
        ) : (
          <p className="text-gray-600">Tidak ada dokter yang ditemukan.</p>
        )}
      </div>
    </div>
    <Footer/>
    </>
  );
};

export default OtherDoctors;
